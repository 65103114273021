<template>
  <div class="huggies-register">
    <div class="wrap-content">
      <h6>
        寶寶生日/預產期*
        <span
          v-if="
            v$.form.year.$error || v$.form.month.$error || v$.form.day.$error
          "
          class="error"
          >請輸入寶寶生日/預產期</span
        >
      </h6>
      <div class="mb-3" style="padding: 0">
        <select
          class="form-control custom-select"
          :class="{ invalid: v$.form.year.$error }"
          style="width: 31%; margin-right: 3%"
          id="year"
          name="year"
          v-model="form.year"
          :disabled="formLoading"
        >
          <option value="" hidden="">出生年</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
          <option value="2018">2018</option>
        </select>
        <select
          class="form-control custom-select"
          :class="{ invalid: v$.form.month.$error }"
          style="width: 31%; margin-right: 3%"
          id="month"
          name="month"
          v-model="form.month"
          :disabled="formLoading"
        >
          <option value="" hidden="">出生月</option>
          <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
        </select>
        <select
          class="form-control custom-select"
          :class="{ invalid: v$.form.day.$error }"
          style="width: 31%"
          id="day"
          name="day"
          v-model="form.day"
          :disabled="formLoading"
        >
          <option value="" hidden="">出生日</option>
          <option v-for="i in dayOptions" :key="i" :value="i">{{ i }}</option>
        </select>
      </div>
      <h6>
        爸爸媽媽姓名*<span v-if="v$.form.parent_name.$error" class="error"
          >請輸入姓名</span
        >
      </h6>
      <div class="mb-3" style="padding: 0">
        <input
          class="form-control"
          :class="{ invalid: v$.form.parent_name.$error }"
          id="parent_name"
          name="parent_name"
          placeholder="請輸入姓名"
          required=""
          v-model="form.parent_name"
          :disabled="formLoading"
        />
      </div>
      <h6>
        手機號碼*<span v-if="v$.form.mobile.$error" class="error"
          >請輸入正確的手機號碼</span
        >
      </h6>
      <div class="mb-3" style="padding: 0">
        <input
          type="tel"
          class="form-control"
          :class="{ invalid: v$.form.mobile.$error }"
          id="mobile"
          name="mobile"
          placeholder="請輸入手機號碼"
          pattern="09[0-9]{8}"
          required=""
          v-model="form.mobile"
          :disabled="formLoading"
        />
      </div>
      <h6>
        EMAIL*<span v-if="v$.form.email.$error" class="error"
          >請輸入電子信箱</span
        >
      </h6>
      <div class="mb-3" style="padding: 0">
        <input
          type="email"
          class="form-control"
          :class="{ invalid: v$.form.email.$error }"
          id="email"
          name="email"
          placeholder="請輸入電子信箱"
          required=""
          v-model="form.email"
          :disabled="formLoading"
        />
      </div>
    </div>
    <div class="btnwrap fixedbottom" id="nextbtn" @click="handleNext">
      <img src="./images/btn_next.png" alt="下一步" />
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

const monthsDay = {
  1: 31,
  2: 30,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};

export default {
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      formLoading: false,
      monthsDay,
      form: {
        year: "",
        month: "",
        day: "",
        parent_name: "",
        mobile: "",
        email: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      liffInfo: "liffHuggies/liffInfo",
      token: "liffHuggies/token",
      me: "liffHuggies/me",
      lineProfile: "liffHuggies/lineProfile",
      isPhoneVerified: "liffHuggies/isPhoneVerified",
    }),
    dayOptions() {
      return this.monthsDay[this.form.month];
    },
  },

  validations() {
    return {
      form: {
        year: { required },
        month: { required },
        day: { required },
        parent_name: { required },
        mobile: {
          required,
          mobile: (val) => {
            return /09[0-9]{8}/.test(val);
          },
        },
        email: { required, email },
      },
    };
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
    async handleNext() {
      const result = await this.v$.$validate();

      if (!result) return;

      this.setLoading(true);
      this.formLoading = true;

      const orgCode = this.$route.params.org_code;
      const url =
        process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/general/register`;

      axios
        .post(
          url,
          {
            name: this.lineProfile.displayName ?? null,
            email: this.form.email,
            mobile: this.form.mobile,
            extra: {
              baby_birthday: `${this.form.year}-${String(
                this.form.month
              ).padStart(2, "0")}-${String(this.form.day).padStart(2, "0")}`,
              parent_name: this.form.parent_name,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "LiffHuggiesValidation" }).then(() => {
            this.formLoading = false;
            this.setLoading(false);
          });
        })
        .catch((err) => {
          console.log(err);
          alert("表單送出錯誤");
          this.formLoading = false;
          this.setLoading(false);
        });
    },
  },
};
</script>

<style lang="scss">
.huggies-register {
  .wrap-content {
    padding: 30px 0;
    border: solid #666;
    border-width: 1px 0 1px 0;
  }

  h6 {
    color: #5d3583;
    text-align: left;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
  }

  .error {
    font-size: 12px;
    color: #fa5555;
  }

  select {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 1rem;
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
      url('data:image/svg + xml;charset=utf8, %3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 4 5"%3E%3Cpathfill="%23343a40"d="M2 0L0 2h4zm0 5L0 3h4z"/%3E%3C/svg%3E') no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-control.invalid,
  .custom-select.invalid {
    border-color: rgb(250, 85, 85);
  }

  .btnwrap {
    margin: 30px auto 100px;
    display: block;
    max-width: 300px;
    z-index: 3;
  }

  .btnwrap img {
    vertical-align: top;
    max-width: 100%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 550px) {
  .btnwrap img {
    vertical-align: top;
    max-width: 80%;
  }
}
</style>
